import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "../../components/Container"
import ExteriorGalleryComponent from "../../components/gallery/ExteriorGalleryComponent"

const ExteriorGalleryPage = () => (
  <Layout>
    <SEO title="Gallery" />
    <Container
      noTitle
      style={{
        background: `#52575a`,
        color: `white`,
      }}
      whitetitle
    >
      <ExteriorGalleryComponent />
    </Container>
  </Layout>
)

export default ExteriorGalleryPage
