import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import GalleryBase from "./GalleryBase"

const useGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { tags: { in: "exterior" } } }
      ) {
        edges {
          node {
            frontmatter {
              picture {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allMarkdownRemark.edges.map(edge => ({
    ...edge.node.frontmatter.picture.childImageSharp,
  }))
}

const ExteriorGalleryComponent = () => (
  <GalleryBase title="Exterior" images={useGallery()} />
)

export default ExteriorGalleryComponent
